import { Injectable } from "@angular/core";
import { Protocol } from "../interfaces/protocols/protocol";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ToolbarServiceService {
	protocolSubject = new Subject<Protocol>();

	constructor() {}
}
